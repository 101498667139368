import ru from './locales/ru.json';
import en from './locales/en.json';
import tj from './locales/tj.json';

export const defaultLocale = 'ru'
export const languages = {
    en,
    ru,
    tj
}
