
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'dropdown-menu',
  data() {
    return {
      isOpen: false,
      selected: '',
      options: ['English', 'Русский', 'Тоҷикӣ'],
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectOption(index: number) {
      this.selected = this.options[index];
      this.isOpen = false;
      switch (index) {
        case 0:
          this.$i18n.locale = 'en';
          break;
        case 1:
          this.$i18n.locale = 'ru';
          break;
        case 2:
          this.$i18n.locale = 'tj';
          break;
        default:
          break;
      }
    },
  },
});
